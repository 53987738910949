#featured-products {
    padding: 100px 0;
    background: #fff;
    padding-bottom: 150px;
}

.products-slide-title.first-title {
    margin-top: -40px;
}
.products-slide-title {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 80px;
    
}
#featured-products h2 span {
    font-size: 35px;
    line-height: 1.03;
    letter-spacing: .88px;
}

