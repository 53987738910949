.title-maestro{
    color: #ffffff;
    font-size: 10px;
    list-style: none;
}

.title-maestro-right{
    margin-left: 300%;
    color: #ffffff;
    font-size: 15px;
    list-style: none;
}