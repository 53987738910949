   #new-store {
    background: #fff;
    padding-bottom: 300px;
    padding-top: 100px;
    padding-bottom: 21%;
}

   .new-store-left {
    position: relative;
    background: var(--black);
    width: 41.5%;
    color: #fff;
    padding: 0 0 75px 100px;
    z-index: 1;
}
.new-store-left h2 {
    white-space: nowrap;
    height: 545px;
    width: 110px;
    position: relative;
    margin-bottom: 100px;
}
.new-store-left h2 span {
    font-size: 92px;
    transform: rotate(-90deg);
    position: absolute;
    top: 110%;
    transform-origin: 0 0;
}

.new-store-left .line {
    position: relative;
    width: 100%;
    padding-right: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}

.circle .dot {
    width: 9px;
    height: 9px;
    background: var(--black);
    border-radius: 50%;
}
.circle.white .dot {
    background: #fff;
}
#new-store .new-store-left .line .dot {
    transform: scale(0);
    transition: all .5s .3s;
}
#new-store.is-inview .new-store-left .line .dot {
    transform: scale(1);
}
.is-inview .new-store-left .line::after {
    width: 100%;
}
.new-store-left .line::after {
    content: '';
    position: relative;
    height: 1px;
    background: #979797;
    width: 0;
    margin-left: 30px;
    transition: all 1s cubic-bezier(.73,.29,0,1) .3s;
}
.new-store-left p {
    color: #d8d8d8;
}
p.big {
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 5.3px;
    text-transform: uppercase;
}
/* Bg big */

.new-store-main-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 74%;
    height: 650px;
    background: #2d2d2d;
    overflow: hidden;
    z-index: 2;
}
.bg.parallax {
    top: -25%;
    left: -25%;
    width: 150%;
    height: 150%;
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover!important;
    background-position: center!important;
}

#new-store-pattern {
    position: absolute;
    bottom: 25vw;
    left: 38%;
}


/* Slide Inner */
#new-store-slide {
    width: 100%;
    overflow: visible;
}
.new-store-slide {
    position: absolute;
    top: 435px;
    background: var(--black);
    right: 38%;
    width: 26%;
    padding: 60px;
    z-index: 3;
    padding-bottom: 130px;
}
.new-store-button-wrap {
    position: absolute;
    bottom: 16%;
    left: 100px;
    text-align: left;
    width: calc(93% - 100px);
}
.new-store-button-wrap .button {
    display: inline-block;
    text-align: center;
    position: relative;
    z-index: 1;
}

.new-store-button-wrap::after {
    content: '';
    position: absolute;
    top: 23px;
    right: 0;
    height: 1px;
    width: calc(100% - 300px);
    background: #979797;
}