.main-menu-images {
    position: absolute;
    width: calc(100% - 450px - 250px);
    height: 100vh;
    margin: 0 auto;
    right: 450px;
    top: 0;
}
/* .menu-images-wrapper {
    position: absolute;
    width: 100%;
    opacity: 0;
    z-index: 0;
    transition: .3s;
    display: flex;
    height: 100%;
} */
