
  video {
    min-width: 100%;
    min-height: 100vh;
    z-index: 1;
  }
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    opacity: 0.8;
    /* Hides the logo z-index */
    z-index: 1;
  }
  .img__overlay {
    width: 240px;
  }
  