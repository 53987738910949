#history-content .wrap-mouse-history {
    position: absolute;
    top: calc(100vh - 200px);
    z-index: 10;
    left: 0;
}

#history-content .mouse {
    padding-left: 0;
    pointer-events: none;
}

.mouse {
    padding: 20px;
    padding-right: 0;
    margin: -20px 0;
}
.mouse-icon {
    width: 35px;
    height: 75px;
    border: 2px solid transparent;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    text-align: center;
    pointer-events: auto;
}

#history-content .mouse .mouse-wheel {
    background: var(--black);
}
.mouse-wheel {
    height: 9px;
    margin: 2px auto 0;
    display: block;
    width: 9px;
    position: relative;
    top: 50px;
    background-color: #fff;
    border-radius: 50%;
    -webkit-animation: 3s cubic-bezier(.37,.01,0,.98) infinite wheel-up-down;
    -moz-animation: 3s cubic-bezier(.37,.01,0,.98) infinite wheel-up-down;
    animation: 2s cubic-bezier(.37,.01,0,.98) infinite wheel-up-down
}

@-webkit-keyframes wheel-up-down {
    0% {
        margin-top: -40px;
        opacity: 0
    }

    30% {
        opacity: 1;
        transform: scale(1)
    }

    100% {
        margin-top: 15px;
        opacity: 0
    }
}

@-moz-keyframes wheel-up-down {
    0% {
        margin-top: -40px;
        opacity: 0
    }

    30% {
        opacity: 1;
        transform: scale(1)
    }

    100% {
        margin-top: 15px;
        opacity: 0
    }
}

@keyframes wheel-up-down {
    0% {
        margin-top: -40px;
        opacity: 0
    }

    30% {
        opacity: 1;
        transform: scale(1)
    }

    100% {
        margin-top: 15px;
        opacity: 0
    }
}

.h-flex {
    position: relative;
    display: flex;
}
.h-colum {
    position: relative;
    width: 24%;
    background-color: #fff;
    box-shadow: 1px 1px 29px 0 rgba(0,0,0,.1);
}
.h-content {
    position: relative;
    width: 76%;
    background-color: #f8f8f8 ;
}
.h-content .section-title {
    text-align: left;
    margin-left: -97px;
    background: unset;
}
.section-title .bg-word-wrap {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}


.h-content .section-title .bg-word {
    background: unset;
    color: #fff;
    opacity: .7;
    font-size: 405px;
}


.section-title .bg-word {
    font-size: 26vw;
    color: rgba(233,233,233,.28);
    width: 100%;
    white-space: nowrap;
    top: 100px;
}
.bg-word {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 405px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 379px;
    letter-spacing: -9.91px;
    text-transform: uppercase;
    color: #fff;
    user-select: none;
    height: 250px;
}

.h-content .row {
    position: relative;
}

.a-tag {
    position: absolute;
    top: 11px;
    left: -163px;
    font-family: Calibre;
    font-size: 30px!important;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: 6.05px;
    color: #222;
}
.b-tag {
    font-family: Calibre;
    font-size: 70px!important;
    color: #222;
}
.h-text {
    position: relative;
    margin-top: 100px;
}

.h-text p {
    font-family: Calibre;
    font-size: 24px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: #2c2c2c;
    margin-bottom: 80px;
}

.h-images {
    position: relative;
    margin-top: 100px;
}
.h-years {
    position: absolute;
    top: 0;
    left: -380px;
}
.h-list {
    text-align: right;
    width: 305px;
    position: relative;
    padding-top: 90px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.h-list[data-year="11"]::before {
    top: 0;
    left: -7px;
}

.h-list::before {
    content: '20';
    position: absolute;
    top: 0;
    left: -12px;
    transition: all .5s cubic-bezier(.25,.8,.25,1);
    transform: translateY(90px);
}

.h-list .h-y.h-select, .h-list::before {
    position: relative;
    font-family: Calibre;
    font-size: 176px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -4.3px;
    color: #222;
    margin-bottom: 50px;
    line-height: inherit;
    opacity: 1;
    padding-right: 0;
}
.h-list .h-y {
    opacity: .32;
    font-family: Calibre;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.3;
    letter-spacing: -.49px;
    color: #222;
    padding-right: 90px;
    position: relative;
    cursor: pointer;
    transition: all .5s cubic-bezier(.25,.8,.25,1);
}
.h-photos {
    position: relative;
    display: flex;
    justify-content: space-between;
}


.h-photos-year {
    position: relative;
    margin-bottom: -100px;
}

.h-photos .h-p-colum:nth-of-type(1) {
    padding-top: 290px;
}

.h-p-colum {
    position: relative;
    width: 45%;
    z-index: 1;
}

.h-p-row {
    position: relative;
    max-width: 393px;
    margin-bottom: 80px;
}
.photo {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 393px;
    height: 424px;
}

.photo-name {
    position: absolute;
    display: flex;
    align-items: center;
    top: 437px;
    width: 100%;
}
.p-line {
    position: relative;
    display: flex;
    align-items: center;
    height: 24px;
    flex-grow: 1;
}
.photo-name p {
    font-family: Calibre;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: -.39px;
    color: #222;
    padding-top: 6px;
    max-width: 75%;
}
.p-line span:nth-of-type(1) {
    position: relative;
    width: 9px;
    height: 9px;
    background-color: #010101;
    border-radius: 50%;
    margin-right: 20px;
}
.p-line span:nth-of-type(2) {
    position: relative;
    height: 2px;
    opacity: .33;
    border: solid 1px #010101;
    margin-right: 20px;
    flex-grow: 1;
}

.h-year {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 405px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 379px;
    letter-spacing: -9.91px;
    text-transform: uppercase;
    color: #fff;
    user-select: none;
    height: 250px;
}



@media screen and (max-width: 1280px){
.h-content .row {
    padding-left: 110px;
}
.h-content .row span {
    margin-right: 5px;
}
.a-tag {
    left: -43px;
}
.h-content h1 span {
    font-size: 40px;
}
.h-content h1 strong {
    font-size: 50px;
}
.section-title .bg-word-wrap {
    top: 190px
}
.h-years {
    left: -330px;
}

}