/* Hide the sections borders */
.slick-slide div {
  box-shadow: none;
  border-color: none;
  color: none;
  background: none;
  outline: none;
}
/* Styled chevrons  */

.swiper-button-next{
  position: absolute;
  bottom: 130px;
  padding: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  /* transform: rotate(90deg); */
  right: -6px;
}
.svg-inline--fa {
  display: var(--fa-display, inline-block);
  padding-top: 21px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  padding: 22px;
  transition: all .8s cubic-bezier(.73,.29,0,1);

}


/* Counter */
.pagination {
  position: absolute;
  bottom: 0;
  padding-bottom: 80px;
  right: 60px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 80px;
  justify-content: space-between;
  font-size: 16px;
}
.pagination::before {
  content: '';
  position: absolute;
  width: 32px;
  height: 1px;
  background: #979797;
  left: 0;
  top: 5px;
  right: 0;
  margin: 0 auto;
}

.new-store-slide .circle {
  position: absolute;
  bottom: 12px;
  left: 60px;
}
.pagination::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 72px;
  width: 1px;
  background: #979797;
}

@media screen and (max-width: 1200px) {
  .carrousel-slider {
    width: 100%;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  /* Fit inside background */
  .carrousel-slider img {
    padding-bottom: 100%;
    position: relative;
    transition: all 0s .5s!important;
  }
}


