#masters {
    background: #000;
}
.wrapper {
    position: relative;
    display: block;
    margin: 0 auto;
    right: 0;
    width: calc(100% - 200px);
}

#masters h2 {
    position: absolute;
    top: 110px;
    color: #fff;
    left: 100px;
}
#masters h2 span {
    font-size: 60px;
}

.masters-logo {
    position: absolute;
    right: 0;
    width: 250px;
    top: 170px;
    /* overflow-clip-margin: content-box;
    overflow: hidden; */
}

#masters .image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1100px;
    margin: 0 auto;
    max-width: 90%;
    position: relative;
}

#masters .image-wrap .masters-images {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.masters-logo .svg {
    transform: scale(2);
}


/* Button bannner */

.button.white {
    background: #fff;
    color: var(--black);
}

.button.white::before {
    background: var(--black);
}
.button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: #fff;
    transition: height .5s cubic-bezier(.73,.29,0,1);
}
.button span {
    position: relative;
    transition: all .8s cubic-bezier(.73,.29,0,1);
    display: block;
    text-align: center;
}

.button {
    position: relative;
    display: block;
    background: var(--black);
    border-radius: 100px;
    color: #fff;
    padding: 18px 35px;
    cursor: pointer;
    padding-bottom: 14px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.13px;
    overflow: hidden;
    transition: all .5s,width 1s cubic-bezier(.73,.29,0,1);
    border: none;
    font-family: 'Calibre',sans-serif;
}
#masters .image-text-button {
    padding: 60px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
#masters .image-text-button {
    padding: 60px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
#masters .image-text-button .image-wrap-button .button {
    width: 280px;
    padding-left: 0;
    padding-right: 0;
}

#masters .image-wrap-button, #masters .image-text-button {
    text-align: center;
}

#masters .image-text-button .image-wrap .button {
    position: relative;
    z-index: 1;
}

.image-wrap-button{
    display: flex;
    align-items: center;
    justify-content: right;
    width: 1100px;
    margin: 0 auto;
    max-width: 90%;
    position: relative;
}


@media screen and (min-width: 1100px){
.button:hover{ 
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    background: #fff;
    transition: all .5s,background .5s .3s;
    color: #fff;
}
    
}
.button:hover::before {
    height: 100%;
    bottom: 0;
    top: initial;
}

::selection {
    background-color: var(--black);
    color: #fff;
}
:focus, button:focus {
    outline: 0 none;
}