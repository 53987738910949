footer  {
    color: rgba(256,256,256,.4);
}
footer .top {
    position: relative;
    background: #1a1a1a;

}

footer .top::before {
    content: '';
    position: relative;
    top: 0;
    left: 0;
    display: block;
    right: 0;
    margin: 0 auto;
    width: 1px;
    height: 95px;
    background: rgba(256,256,256,.3);
}
footer .top .logo {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin: 40px 0;
}

footer .lines {
    display: block;
    width: 100%;
    position: relative;
    text-align: center;
}

footer .lines::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    background: #5e5e5e;
    height: 1px;
}

footer .lines .circle {
    margin: 0 auto;
    display: inline-block;
    width: 100px;
    position: relative;
    background: #1a1a1a;
}
footer .lines .circle .dot {
    margin: 0 auto;
}

footer .navigation {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

footer .navigation .left {
    display: flex;
    justify-content: space-between;
}
footer .navigation>* {
    width: calc(50% - 50px);
}
footer .navigation .left .column a {
    display: block;
    padding-top: 20px;
    font-size: 22px;
    width: fit-content;
}
.link-dot {
    position: relative;
    transition: color .3s;
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
}
.link-dot::before {
    content: '';
    position: absolute;
    top: 23px;
    left: -35px;
    width: 9px;
    height: 9px;
    opacity: 0;
    background: #fff;
    border-radius: 5px;
    transition: all .6s cubic-bezier(.25,.8,.25,1) 0s;
}


footer .navigation .right {
    position: relative;
}


footer .navigation .right::before {
    content: '';
    width: 0;
    height: 1px;
    top: -11px;
    position: absolute;
    left: auto;
    right: 0;
    transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
    background: #fff;
}

footer .navigation .right a {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;
}

footer p.big {
    letter-spacing: 2px;
    height: 20px;
    margin-right: 40px;
    margin-bottom: 0;
    font-size: 1.1vw;
    margin-right: 2.5vw;
}

footer .right a h2 {
    font-size: 70px;
    font-size: 4.7vw;
    font-weight: 900;
    height: 46px;
    transition: all .6s cubic-bezier(.25,.8,.25,1) 0s;
}

footer .right:hover a h2 {
    transform: translateX(5px);
}


@media screen and (min-width: 1100px){
footer .navigation .left .column a:hover {
    color: #fff;
}
.link-dot:hover {
    animation: link-dot .5s .1s;
}
.link-dot:hover::before {
    width: 9px;
    border-radius: 50%;
    left: -19px;
    opacity: 1
}
footer .navigation .right:hover::before {
    width: calc(100%);
    left: 0;
    right: auto;
}
}




/* Form */

footer .bottom {
    margin-top: 90px;
    background: #101010;
    padding: 100px 0;
    padding-bottom: 70px;
}

.newsletter-form {
    display: flex;
    align-items: center;
    margin-bottom: 70px;
}
.newsletter-form p {
    max-width: 115px;
    line-height: 1.63;
    color: #fff;
    height: 72px!important;
    margin-right: 60px!important;
}

.newsletter-form * {
    flex: 1;
}
.newsletter-form .newsletter-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background: #101010;
    line-height: 72px;
    text-align: center;
    font-size: 20px;
    opacity: 0;
    transition: all .5s .2s;
    pointer-events: none;
}
.newsletter-form .newsletter-message span {
    display: block;
    opacity: 0;
    transform: translateY(10px);
    transition: all .5s;
}

.input {
    position: relative;
    padding-right: 35px;
}   

.input label {
    position: absolute;
    color: #fff;
    font-weight: 900;
    font-size: 33px;
    text-transform: uppercase;
    left: 20px;
    top: 24px;
    transition: all .6s cubic-bezier(.25,.8,.25,1) 0s;
    z-index: 2;
}

.input input {
    display: block;
    width: 100%;
    background: #1a1a1a;
    border: none;
    padding: 21px 20px;
    color: #1a1a1a;
    font-size: 20px;
    font-family: 'Calibre',sans-serif;
    position: relative;
    z-index: 1;
    font-weight: 900;
    font-size: 33px;
    text-transform: uppercase;
}

.input.active label {
    color: #757575;
    top: -25px;
    font-size: 20px;
    z-index: 0
}

#franq-form .s-form .input.active label {
    font-size: 20px;
    top: -25px;
    padding-right: 0;
}

.ps-text .input.active label {
    top: -5px
}

.input.active #news-form-date{
    opacity: 1;
}

#franq-form .s-form .input.active label{
    top: -40px
}

.newsletter-form input {
    color: #fff !important;
    padding-bottom: 10px !important;
}

.newsletter-form button {
    max-width: 200px;
    border: none;
    cursor: pointer;
    font-family: 'Calibre',sans-serif;
}

/* After sent message */
.form-success {
    display: flex;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    
  }
  

/* Social */
footer .social {
    position: relative;
    text-align: center;
}

footer .social::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #505050;
}

footer .social .inner {
    display: inline-flex;
    background: #101010;
    align-items: center;
    position: relative;
    padding: 0 40px;
}

.social a {
    font-size: 22px;
    color: #fff;
    padding: 10px 20px;
    transition: all .3s;
}

footer .copy {
    display: flex;
    margin-top: 70px;
    padding-bottom: 0;
    justify-content: space-between;
}

footer .copy p:first-child a {
    margin-left: 10px;
}

footer .copy a {
    transition: all .3s;
    position: relative;
}


@media screen and (min-width: 1100px){
.social a:hover {
    opacity: .5;
}}

