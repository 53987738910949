header {
  padding-top: 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 40;
  transition: all 1s cubic-bezier(.25,.8,.25,1) 0s;
}
header::before{
  content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: all 1s cubic-bezier(.25,.8,.25,1) .2s;
    box-shadow: 0 0 30px rgba(0,0,0,.1);
}
header::selection {
  background-color: #222222;
  color: #fff;
}

header .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.navbar {
  width: 100%;
  position: relative;
  z-index: 50;
  display: grid;
  grid-template-columns: repeat(3, 1fr);;
  grid-column-gap: 26%;
  padding: 50px;
  align-items: center;
  background: transparent;;
}

.navbar.active{
  background-color: transparent;
  height: 10px;
  /* background-color: #222222; */

}

.navbar.active .burger-btn span{
  color: #ffffff
}

.burger-btn {
  margin-left: 120px;
  position: relative;
  cursor: pointer;
  color: #222222;
}

.burger-btn span {
  height: 10px;
  font-size: 50px;
  color: #222222;
}
.title-maestro-right, .title-maestro{
  color: #222222;
}

.navbar.active .title-maestro-right,
.navbar.active .title-maestro{
  color: #ffffff;
  z-index: -3;
}


/* Menu */


.menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  top: -150%;
  z-index: 39;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all .8s cubic-bezier(.73,.29,0,1);
  pointer-events: none;
}

.menu.active {
  top: 0;
  pointer-events: auto;
}

.main-menu-inner {
  position: relative;
  width: calc(100% - 240px);
  text-align: right;
}

.menu-item {
  position: relative;
  display: block;
}

.menu-item span {
  position: relative;
  font-family: Calibre;
  font-size: 38px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -.16px;
  text-transform: uppercase;
  text-align: right;
  color: #d3d3d3;
  background-color: #fff;
  padding-left: 30px;
  display: inline-block;
  transition: all .3s;
}

.menu-item.active span {
  color: #222;
  animation: link-dot .5s .3s;
}

.menu-item::before {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  opacity: .42;
  border: solid 1px #222;
  top: 21px;
  left: -20%;
  transition: all 1.2s cubic-bezier(.25,.8,.25,1);
}

.menu-item.active::before {
  width: 120%;
}

.menu-item.active span::after {
  transform: scale(1);
  transition-delay: .3s;
}

.menu-item span::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  left: 12px;
  top: 17px;
  border-radius: 50%;
  transition: all .5s cubic-bezier(.73,.29,.18,1.69);
  transform: scale(0);
  display: block;
  background-color: #222;
}


/* Hover */


.menu {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: 10vh 0 25vh;
}


.menu__item-img {
	pointer-events: none;
	position: absolute;
	height: 50vh;
	max-height: 400px;
	opacity: 0;
	left: 60%;
	top: 50%;
	transform: translate3d(calc(-100% - 6vw),-30%,0) translate3d(0,20px,0);
}

.menu__item-link:hover + .menu__item-img {
	opacity: 1;
	transform: translate3d(calc(100% - 6vw),-30%,0) rotate3d(0,0,1,4deg);
	transition: all 0.4s;
}








@media screen and (max-width: 768px) {
  /* .main-menu-inner {
      width: 100%;
  } */
  /* nav{
      padding-left: 2rem;
          padding-right: 2rem;
  } */

}




