.carrousel img:hover {
  transform: scale(1.3);
}
/* Img effect on hover */
.space__ice {
  height: 60vh;
  padding-top: 20%;
  width: 50vw;
}
.space__ice:hover {
  animation: float 6s ease-in-out infinite;
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.space__ice:hover .product-text {
  margin-right: 10vh;
  display: block;
  padding: 30px 10px 0px 40px;
  border-radius: 120px;
  transform: scale(1.1);
  transition: all 0.9s ease-out;
  background-color: #f2c59d;
}
.product-text {
  float: left;
  width: 200px;
  display: none;
}
/* Hide the sections borders */
.slick-slide div {
  box-shadow: none;
  border-color: none;
  color: none;
  background: none;
  outline: none;

}


/* Styled chevrons  */
.slick-arrow-left {
  position: absolute;
  margin-block: 100px;
  margin-left: 10vw;
  z-index: 3;
  opacity: 1;
  background-color: #ffffff;
  font-size: 40px;
  border-radius: 100px;
  padding: 6px 20px 17px 22px;
}
.slick-arrow-right {
  position: absolute;
  display: block;
  margin-left: 85vw;
  margin-block: -204px;
  font-size: 40px;
  z-index: 3;
  opacity: 1;
  background-color: #ffffff;
  border-radius: 100px;
  padding: 6px 20px 17px 22px;
}
.slick-arrow-left:hover {
  background-color: #000000;
  color: #ffffff;
  transform: scaleX(1);
}
.slick-arrow-right:hover {
  background-color: #000000;
  color: #ffffff;
  transform: translateY(1);
}

@media screen and (max-width: 1200px) {
  .carrousel img {
    width: 100%;
    height: auto;
    transition: all 0.9s ease-in-out;
    cursor: pointer;

  }

  .space__ice:hover .product-text {
    display: none;
  }
}


