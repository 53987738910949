.container-loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: var(--black);
    justify-content: center;
    z-index: 1000;
    align-items: center;
}
.content {
    margin: 0 auto;
  }




.logo-loader img {
    animation: loader .8s infinite alternate
}


@-moz-keyframes loader {
    0% {
        opacity: .2;
        transform: scale(.98)
    }

    100% {
        opacity: 1;
        transform: scale(1)
    }
}

@-webkit-keyframes loader {
    0% {
        opacity: .2;
        transform: scale(.98)
    }

    100% {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes loader {
    0% {
        opacity: .2;
        transform: scale(.98)
    }

    100% {
        opacity: 1;
        transform: scale(1)
    }
}