*, :after, :before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
} 

body, html {
    margin: 0;
    padding: 0;
    /* overflow: hidden; */
    width: 100%;
    max-width: 100%;
    font-family: 'Calibre',sans-serif;
    color: #222222;
    background-color: #f8f8f8;
    line-height: 1;

}

:root, :host {
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
    --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
    --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
    --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
    --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
    --fa-font-sharp-regular: normal 400 1em/1 "Font Awesome 6 Sharp";
    --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
    --black: #222;

} 

fieldset, figcaption, figure, form, h1, h2, h3, h4, h5, h6, input, label {
    padding: 0;
    margin: 0;
    font-family: 'SilkSerif';

}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    font-family: 'SilkSerif';
}
h2 {
    text-transform: uppercase;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

h2 strong {
    font-size: 41px;
    line-height: 1;
    letter-spacing: -1px;
    font-weight: 900;
}

h5 {
    font-size: 28px;
    line-height: 1.07;
    letter-spacing: -1.6px;
    position: relative;
    text-transform: uppercase;
    font-weight: 900;
    display: inline-block;
}



section {
    position: relative;
    overflow: hidden;
    display: block;
}

img {
    border: 0;
    max-width: 100%;
}


abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    list-style: none;
}

svg:not(:root) {
    overflow-clip-margin: content-box;
    overflow: hidden;
}

a, a:hover {
    text-decoration: none;
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    color: inherit;
}


p {
    font-size: 13px;
    line-height: 1.31;
}

input, select {
    vertical-align: middle;
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: field;
    margin: 0em;
    padding: 1px 2px;
    border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
}

footer{
    background: #1a1a1a;
}

*::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(54, 54, 54, 0.5);
}

span[data-scroll], a[data-scroll] {
    display: block;
}

.ss {
    font-family: 'SilkSerif';
}