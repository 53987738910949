#news-home {
    background: #fff;
    --offset: 0vw;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
}
#news-home .news-mosaic {
    margin-left: -100px;
    margin-bottom: -2px;
}
#news-home .news-mosaic h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: marquee 20s linear infinite;
    animation-play-state: running;
    position: relative;
}

#news-home .news-mosaic h2 span {
    line-height: 45px;
}
#news-home .news-mosaic h2 span {
    font-size: 70px;
    height: 54px;
    margin: 0 30px;
}

@keyframes marquee {
    0% {
        left: 0
    }

    100% {
        left: -100%
    }
}

/* Columns */
.news-columns {
    border-top: solid 1px #979797;
    position: relative;
    background: #fff;
}

.news-columns .swiper-wrapper {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: calc(100% - 200px);
    margin: 0 auto;
    align-items: end;

}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}
.news-columns .new-column {
    border-left: solid 1px #979797;
    padding: 45px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
    padding-top: 12vw;
    height: 100%;
    cursor: pointer;
}
.new-column .news-img {
    padding-bottom: 100%;
    overflow: hidden;
}
.new-column h5 {
    position: absolute;
    top: 45px;
    left: 20px;
}
h5.little {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    color: var(--black);
}
h5.little .line {
    background: var(--black);
    opacity: 1;
}
h5 .line {
    position: absolute;
    top: -15px;
    left: 0;
    width: 0;
    height: 1px;
    background: rgba(34,34,34,.2);
    transition: all .6s cubic-bezier(.25,.8,.25,1);
}
.is-inview h5 .line {
    width: 100%;
    transition-delay: .5s;
}
.bg-column {
    position: absolute;
    top: 442px;
    left: 11%;
    width: 78%;
    height: 38%;
    background-size: cover!important;
    background-position: center!important;
    background-image: url(../../../assets/img/stores//file-1633098170964.jpg)
}
.new-column .news-img .bg {
    transition: all .6s cubic-bezier(.25,.8,.25,1) 0s;
}

.new-column .news-img {
    padding-bottom: 100%;
    overflow: hidden;
}

