#partners {
    background: rgba(216,216,216,.2);
    padding: 150px 0;
}
#partners .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#partners .left {
    width: 250px;
    height: 50px;
    padding-top: 27px;
}

.right{
    display: flex;
    align-items: center;
    width: calc(100% - 300px);
}
#partners .right .partner {
    padding: 0 40px;
    width: 20%;
    padding: 0 2.5vw;
}